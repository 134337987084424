<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2 d-flex">
      <button class="button-primary text-white d-flex mr-1 px-[5px]" @click="$router.back()">
        <span class="k-arrow-left-2 h-100 font-bold text-10" style="line-height: 1.5;"></span>
      </button>
      <span class="text-10 text--dark">Invoice</span>
    </div>
    <ValidationObserver ref="formRules" VSlot="{ }">
      <BRow>
        <BCol md="7">
        <!-- form -->
          <BRow>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Partner <span class="text-danger">*</span>
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Posisi"
                  rules="required"
                >
                  <VSelect
                    v-model="idPartner"
                    label="name"
                    :reduce="option => option.id"
                    :options="listPartners"
                    placeholder="Ketik untuk mencari..."
                    @search="(search) => search.length > 0 ? onSearch(search) : ''"
                    @input="handleInput"
                    disabled
                  />
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Periode <span class="text-danger">*</span>
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Periode"
                  rules="required"
                >
                  <MonthlyPicker
                    v-model="periode"
                    :month-labels="monthlabel"
                    :max="maxDatePicker"
                    date-format="MMM YYYY"
                    :disabled="true"
                    :clear-option="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
          </BRow>
        </BCol>
        <BCol md="5" class="d-flex justify-content-end align-items-end pb-1">
          <button class="button-primary ml-1 fw-bold px-[40px] py-[8px]" @click="disburseAll()" :disabled="listDisbursementId.length < 3">
            Disburse Semua
          </button>
        </BCol>
      </BRow>
    </ValidationObserver>
    <div class="d-flex flex-column py-[20px] items-end border-t">
      <span class="text-8 text--dark font-bold">Total Nominal: {{ IDR(totalNominal) }}</span>
    </div>
    <div id="table">
      <BTable
        :items="items"
        :fields="fields"
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        show-empty
      >
        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(disbursement_status)="data">
          <span v-if="data.item.disbursement_status.toUpperCase() === 'FAILED'" class="status status-red">failed</span>
          <span v-if="data.item.disbursement_status.toUpperCase() === 'PENDING'" class="status status-yellow">disbursement waiting</span>
          <span v-if="data.item.disbursement_status.toUpperCase() === 'COMPLETED'" class="status status-green">success</span>
        </template>
        <template #cell(action)="data">
          <BButton
            size="sm"
            variant="primary"
            v-if="data.item.disbursement_status.toUpperCase() === 'FAILED'"
            @click="sendDisbursement(data.item.disbursement_id)"
            :disabled="loadingSubmit"
          >
            Disburse
          </BButton>
        </template>
      </BTable>
    </div>
  </BCard>
</template>

<script>
import VSelect from 'vue-select'
import MonthlyPicker from 'vue-monthly-picker'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { komtimAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import { alertError, alertSuccess } from '@toast'
import { required } from '@validations'
import { LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import { tableMonitor } from './config'
import '@core/scss/vue/libs/vue-select.scss'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VSelect,
    MonthlyPicker,
  },
  data() {
    return {
      IDR,
      fields: tableMonitor,
      visible: false,
      loading: false,
      loadingSubmit: false,
      alertError,
      alertSuccess,
      required,
      YEAR_MONTH,

      idPartner: null,
      periode: null,
      listPartners: [],
      listAdminFee: [],
      items: [],
      indexSelected: null,
      isTax: false,
      money: {
        thousands: '.',
        prefix: 'Rp ',
        precision: 0,
        masked: false,
      },
      monthlabel: LABELMONTH,
      adminServicefee: 4440,
      totalNominal: 0,
      listDisbursementId: [],
    }
  },
  async mounted() {
    this.getDetailData()
  },
  computed: {
    maxDatePicker() { return this.$moment().endOf('month') },
  },
  methods: {
    async getDetailData() {
      const url = `v1/invoices/${this.$route.params.id}/disbursement`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailData = data

          this.loadForm()
        })
        .catch(err => {
          this.alertError(err)

          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
    handleInput(val) {
      this.items = []
      if (val) this.getListTalent()
    },
    setAdminFee(index) {
      this.indexSelected = index
    },
    setIndexAdminFee(val) {
      if (val.description && !val.id) this.items[this.indexSelected].admin_fee = parseInt(val.description, 10)
      else if (val.description && val.id) {
        this.items[this.indexSelected].admin_fee = parseInt(val.admin_fee, 10)
        this.items[this.indexSelected].admin_fee_id = val.id
      }
    },
    loadForm() {
      this.items = this.detailData.data
      this.periode = YEAR_MONTH(this.detailData.invoice_period)
      this.idPartner = this.detailData.partner_name
      this.totalNominal = this.detailData.total_nominal

      const filterComplete = this.detailData.data.filter(el => el.disbursement_status.toUpperCase() === 'FAILED')
      this.listDisbursementId = filterComplete.map(el => el.disbursement_id)
    },
    async disburseAll() {
      for (let i = 0; i < this.listDisbursementId.length; i += 1) {
        /* eslint-disable no-await-in-loop */
        await this.disbursement(this.listDisbursementId[i])
      }
    },
    async sendDisbursement(id) {
      this.loadingSubmit = true

      const url = `v1/invoices/${id}/disbursement`
      await komtimAxiosIns
        .put(url)
        .then(() => {
          const text = 'Disbursement berhasil'
          this.alertSuccess(text)

          this.getDetailData()
          this.loadingSubmit = false
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    async disbursement(id) {
      this.loadingSubmit = true

      const url = `v1/invoices/${id}/disbursement`
      await komtimAxiosIns
        .put(url)
        .then(() => {
          const text = 'Disbursement berhasil'
          this.alertSuccess(text)

          this.loadingSubmit = false
          return new Date()
        })
        .catch(error => {
          this.alertError(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.status {
  padding: 1px 10px;
  text-transform: capitalize;
  border-radius: 5px;

  &-red {
    border: 1px solid #E31A1A;
    background: #FFEDED;
    color: #E31A1A;
  }
  &-yellow {
    border: 1px solid #FBA63C;
    background: #FFF2E2;
    color: #FBA63C;
  }
  &-green {
    border: 1px solid #34A770;
    background: #DCF3EB;
    color: #34A770;
  }
}
</style>
